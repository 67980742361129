// Framework imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";

// MUI imports
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import MobileStepper from "@mui/material/MobileStepper";

// MUI icon imports
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// HSA imports
import { authenticationService } from "../../common/services";
import { shortenString, viewerType } from "../../common/utils/Utils";
import Backend from "../../common/utils/Backend";
import StepFileSelection from "./CreateProjectdialogComponents/StepFileSelection";
import StepMetaData from "./CreateProjectdialogComponents/StepMetaData";
import StepProjectModuleSelection from "./CreateProjectdialogComponents/StepProjectModuleSelection";

const styles = {
  divider: {
    height: "1px", // Modify this to adjust the thickness
    backgroundColor: "rgb(148,148,148)", // You can also change the color here
  },
};
class CreateProjectDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      activeStep: 0,
      projectTypes: [],
      origFiles: [],
      formData: {
        name: props.newdefaultname,
        projectType: "",
        metaData: {},
        files: [],
        projectProperties: {},
        projectStringProperties: {},
        groups: [],
        caseId: null,
      },
    };
    this.user = null;

    Backend.loadAvailableProjectTypes(false).then((projectTypes) => {
      const { formData } = this.state;
      // order project types by order attribute
      projectTypes = projectTypes.sort((a, b) => a.order - b.order);
      Backend.getLicensingInfo((license) => {
        if (license.activeModules.length > 0) {
          if (license.licenseStatus !== "VALID") {
            authenticationService.logout();
            this.props.history.push("/licensing");
            return;
          }

          projectTypes = projectTypes.map((projectType) => {
            if (license.activeModules.length > 0) {
              const activeModule = license.activeModules.find(
                (x) => x.name === projectType.name
              );
              if (activeModule) {
                projectType.expirationDate = activeModule.expirationDate;
                projectType.isValid = !activeModule.isExpired;
                projectType.expiresInDays = activeModule.expiresInDays;
              } else {
                projectType.isValid = false;
              }
            } else {
              projectType.isValid = true;
            }
            return projectType;
          });

          projectTypes = projectTypes.filter(
            (projectType) => typeof projectType.expirationDate !== "undefined"
          );
          if (projectTypes.length > 0) {
            // select default project type
            const firstValidProjectType = projectTypes.find((pt) => pt.isValid);
            if (firstValidProjectType) {
              formData.projectType = firstValidProjectType.name;
              for (let metaField of projectTypes.find(
                (c) => c.name === formData.projectType
              ).metaData) {
                // use existing value or init dict entry with empty string
                formData.metaData[metaField] =
                  metaField === "Datum" || metaField === "Date"
                    ? this.getDate()
                    : formData.metaData[metaField] || "";
              }
            }
          }
        }
        // write received info into state
        this.setState({
          projectTypes: projectTypes,
          formData,
          metaData: {},
          files: [],
        });

        Backend.getCurrentUser((user) => (this.user = user));
        Backend.getCurrentUserGroups((response) => {
          const formData = this.state.formData;
          formData.groups = response;
          this.setState({ formData });
        });
      });
    });
  }

  handleKeyPress = (event) => {
    if (!this.props.open) return;

    if (event.key === "Enter") {
      this.handleNext();
    }
  };

  componentDidMount = () => {
    document.addEventListener("keyup", this.handleKeyPress);
  };

  componentWillUnmount = () => {
    document.removeEventListener("keyup", this.handleKeyPress);
  };

  componentDidUpdate = (prevProps) => {
    // on open dialog
    if (prevProps.open !== this.props.open && this.props.open) {
      const { formData } = this.state;
      if (this.props.project) {
        const promises = this.props.project.imageFileIds.map((fileId) => {
          return Backend.getFileInfo(fileId);
        });
        Promise.all(promises).then((files) => {
          formData.files = [...new Set(files.map((file) => file.relativePath))];
          formData.name = this.props.project.name;
          formData.projectType = this.props.project.type;
          this.setState({
            submitted: false,
            activeStep: 0,
            formData,
            origFiles: formData.files,
          });
        });
      } else {
        formData.files = [];
        formData.name = this.props.newdefaultname;
        this.setState({
          submitted: false,
          activeStep: 0,
          formData,
          origFiles: [],
        });
      }
    }
  };

  /**
   * Defines what happens when the next button is pressed.
   * When moving a step forward, checks are made to see if the next option is necessary.
   * When finishing the final step, project is created or updated.
   */
  handleNext = async () => {
    if (this.props.project && this.state.activeStep === 0) {
      this.setState({ activeStep: 2 });
      return;
    }
    const { formData } = this.state;

    // Go to next step
    if (this.state.activeStep < 2) {
      if (formData.projectType === "") {
        window.showWarningSnackbar("Please select a Project Module!");
        return;
      }

      let nextStep = this.state.activeStep + 1;

      // Skip metadata step if no metadata is required
      if (nextStep === 1 && Object.keys(formData.metaData).length === 0) {
        nextStep++;
      }
      this.setState({ activeStep: nextStep, formData });
      return;
    }

    // Submit data
    if (this.state.formData.files.length === 0) return;

    // last step -> submit data
    this.setState({ submitted: true });

    // Update project
    if (this.props.project) {
      const filesRemoved = this.state.origFiles.filter(
        (oF) => !formData.files.includes(oF)
      );

      const filesRemovedInfo = [];
      const promises = filesRemoved.map(async (filePath) => {
        const fileAnnoCount = await Backend.getAnnotationCount(
          this.props.project.id,
          filePath
        );
        if (fileAnnoCount > 0) {
          filesRemovedInfo.push({
            filePath: filePath,
            annotationCount: fileAnnoCount,
          });
        }
      });
      await Promise.all(promises);

      const annotationsToLoose = filesRemovedInfo.reduce(
        (acc, file) => acc + file.annotationCount,
        0
      );

      const continueImport =
        annotationsToLoose === 0 ||
        (await window.openConfirmationDialog(
          "Warning: You are removing annotated files",
          `The following ${filesRemoved.length} ${
            filesRemoved.length === 1 ? "file" : "files"
          } with a total of ${annotationsToLoose} annotations will be deleted from the project "${
            this.props.project.name
          }". Do you want to continue?`,
          filesRemovedInfo.map(
            (file) =>
              `${shortenString(file.filePath, 60)} (${file.annotationCount})`
          )
        ));
      if (!continueImport){
        this.setState({ submitted: false });
        return;
      }

      Backend.updateProject(this.props.project.id, formData)
        .then(() => {
          this.handleClose();
        })
        .catch((error) => {
          this.setState({ submitted: false });
          console.error("Error updating project:", error);
          window.openErrorDialog(error.toString(), "Error updating project");
        });
      return;
    }

    // Histology Classification and Histology Point Counting projects have a special naming convention
    if (
      formData.projectType.includes("HistoClassification") ||
      formData.projectType.includes("HistoPointCounting")
    ) {
      let name = formData.files[0];
      name = name.replace(/\\/g, "/");
      let name_array = name.split("/");
      name = name_array[name_array.length - 1];
      name = name.split(".")[0];
      name_array = name.split("-");
      let i = 0;
      while (i < name_array.length - 1) {
        if (i === 0) {
          name = name_array[i];
        } else {
          name = name + "-" + name_array[i];
        }
        i = i + 1;
      }

      formData.name = name;
      this.setState({ formData: formData });
    }

    // Create new project
    Backend.createProject(this.state.formData)
      .then((e) => {
        this.setState({ submitted: true });
        this.handleClose();
        if (formData.projectType.includes("ProteomeAnalysis")) {
          Backend.loadProject(e.projectId).then((project) => {
            Backend.getCurrentUser((user) => {
              const projectModel = {
                name: project.name,
                user: user.fullName,
                id: project.id,
                readableId: project.readableId,
                metaData: JSON.parse(project.metaData),
                type: project.type,
                tools: project.viewerConfig.project.tools,
                job: project.viewerConfig.project.job,
                files: project.files,
              };
              Backend.saveProject(projectModel)
                .then((data) => {
                  if (data.success) {
                    this.props.handleActiveTabChange(0);
                    Backend.setProjectsPending([project.id], false, () => {
                      console.log("job ready to start!");
                    });
                  }
                })
                .catch((error) => {
                  window.openErrorDialog(
                    error +
                      "\n" +
                      "Project couldn't be saved!\nTry again later."
                  );
                });
            });
          });
        } else {
          this.props.history.push(
            viewerType(formData.projectType) + e.projectId
          );
        }

        // Add project to predefined case
        if (this.state.formData.caseId !== null) {
          Backend.editCaseProjects(
            this.state.formData.caseId,
            e.projectId,
            "add"
          );
        }
      })
      .catch((error) => {
        this.setState({ submitted: false });
        console.error("Error creating project:", error);
        window.openErrorDialog(error.toString(), "Error creating project");
      });
  };

  handleBack = () => {
    const { formData } = this.state;
    if (this.state.activeStep === 0) {
      this.props.onClose();
      return;
    }

    if (this.props.project) {
      this.setState({ activeStep: 0 });
      return;
    }
    let prevStep = this.state.activeStep - 1;

    // Skip metadata step if no metadata is required
    if (prevStep === 1 && Object.keys(formData.metaData).length === 0) {
      prevStep--;
    }

    // Reset files
    formData.files = [];
    this.setState({ activeStep: prevStep, formData: formData });
  };

  handleClose = () => {
    this.props.onClose();
  };

  onChangeName = (e) => {
    const { formData } = this.state;
    formData.name = e;
    this.setState({ formData });
    if (this.props.project) {
      let project = this.props.project;
      project.name = e;
    }
  };

  onChangeCaseId = (e) => {
    const { formData } = this.state;
    formData.caseId = e;
    this.setState({ formData });
  };

  /**
   * Fills in project parameters for saving.
   * Checks for valid expiration date and gives expiration notice.
   * @param {Object} e Selected module from step 0, including properties.
   */
  onProjectType = (e) => {
    const { formData, projectTypes } = this.state;
    formData.projectProperties = e.projectProperties;
    formData.projectStringProperties = e.ProjectStringProperties;
    formData.metaData = e.metaData;
    formData.toolsInProject = e.toolsInProject;
    this.setState({ formData: formData });

    if (!e.isValid && e.expirationDate) {
      window.openWarningDialog(
        "This Project Module expired on " +
          e.expirationDate.replace("T00:00:00", "") +
          ".\nPlease contact HS Analysis GmbH to updated the licence!"
      );
      return;
    }
    if (this.props.project) {
      window.openWarningDialog(
        "Project already created and the project type can't be changed afterwards.\nPlease create a new Project instead."
      );
      return;
    }

    formData.projectType = e.name;
    formData.metaData = {};

    const selectedProjectType = projectTypes.find(
      (pt) => pt.name === formData.projectType
    );
    if (
      ("selectedProjectType",
      selectedProjectType &&
        selectedProjectType.name.includes("ProteomeAnalysis"))
    ) {
      formData.projectStringProperties =
        selectedProjectType.projectStringProperties;
      formData.projectProperties = selectedProjectType.projectProperties;
      for (const [key, value] of Object.entries(
        formData.projectStringProperties
      )) {
        formData.metaData[key] = value.split(",")[0];
      }
      for (const [key, value] of Object.entries(formData.projectProperties)) {
        formData.metaData[key] = value;
      }
    }

    for (let metaField of projectTypes.find((c) => c.name === e.name)
      .metaData) {
      // use existing value or init dict entry with empty string
      formData.metaData[metaField] =
        metaField === "Datum" || metaField === "Date"
          ? this.getDate()
          : formData.metaData[metaField] || "";
    }
    this.setState({ formData });
  };

  onChangeMetaData = (field, e) => {
    const { formData } = this.state;
    if (e === null) {
      // delete field
      delete formData.metaData[field];
    } else {
      // update fiel value
      formData.metaData[field] = e;
    }
    this.setState({ formData });
  };

  onChangeFiles = (e) => {
    const { formData } = this.state;
    formData.files = e;
    this.setState({ formData });
  };

  /**
   * Get today's date in format dd.mm.yyyy
   * @returns {string} today's date in format dd.mm.yyyy
   */
  getDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return dd + "." + mm + "." + yyyy;
  };

  render() {
    const { classes } = this.props;
    const { activeStep, formData, projectTypes, submitted } = this.state;

    return (
      <Dialog onClose={this.handleClose} open={this.props.open} maxWidth="lg">
        <DialogTitle>Create New Project</DialogTitle>
        <Divider className={classes.divider} />
        <br />
        <ValidatorForm onSubmit={this.handleNext} style={{ width: 800 }}>
          {activeStep === 0 && (
            <StepProjectModuleSelection
              key="0"
              name={formData.name}
              projectType={formData.projectType}
              onChangeName={this.onChangeName}
              onSaveName={this.onSaveName}
              availableTypes={projectTypes}
              onProjectType={this.onProjectType}
            />
          )}
          {activeStep === 1 && (
            <StepMetaData
              key="1"
              formData={formData}
              projectStringProperties={formData.projectStringProperties}
              projectProperties={formData.projectProperties}
              onChangeMetaData={this.onChangeMetaData}
            />
          )}
          {activeStep === 2 && (
            <StepFileSelection
              key="2"
              selectionTarget="Project"
              project={this.props.project}
              formData={formData}
              onChangeFiles={this.onChangeFiles}
              onChangeCaseId={this.onChangeCaseId}
            />
          )}
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                type="submit"
                disabled={
                  submitted ||
                  (this.state.activeStep === 2 &&
                    this.state.formData.files.length === 0)
                }
              >
                {/* Change Next Button depending on if you're editing a project
                 or creating a new one */}
                {this.props.project && this.state.activeStep > 1
                  ? "Save"
                  : "Next"}

                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={this.handleBack}
                disabled={!this.props.project && activeStep === 0}
              >
                <KeyboardArrowLeft />
                {"Back"}
              </Button>
            }
          />
        </ValidatorForm>
      </Dialog>
    );
  }
}

CreateProjectDialog.propTypes = {
  project: PropTypes.object,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
  newdefaultname: PropTypes.string,
  handleActiveTabChange: PropTypes.func,
  open: PropTypes.bool,
  history: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(CreateProjectDialog));
