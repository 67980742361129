// FindFilesDialog.jsx
// Screen shown when importing a project to verify automatically found files and specify missing or duplicate files. Map files of imported project or re-map missing files.

// Copyright HS Analysis GmbH, 2019
// Author: Sebastian Murgul, Valentin Haas, Viktor Eberhardt

// Framework imports
import React, { Component } from "react";
import PropTypes from "prop-types";

// Material-UI imports
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorIcon from "@mui/icons-material/Error";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import RefreshIcon from "@mui/icons-material/Refresh";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

// HSA imports
import Backend from "../../common/utils/Backend";
import LazyRender from "../../common/components/LazyRender";

const styles = () => ({
  root: {},
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    minWidth: 750,
  },
});

/**
 * Enum for project action mode.
 */
export const ProjectActionMode = Object.freeze({
  Import: 0,
  Update: 1,
  Query: 2,
});

/**
 * Content of the dialog, depending on the project action mode.
 */
const _dialogContent = Object.freeze({
  [ProjectActionMode.Import]: {
    title: "Map Files of Imported Project",
    text: "An exported project does not contain image files, only their respective names. ",
  },
  [ProjectActionMode.Update]: {
    title: "Re-Map Missing Files",
    text: "Not all files previously associated with this project could be found in their original location. ",
  },
});

class FindFilesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData ?? {
        fileMappings: [],
      },
      openAccordion: null,
      dialogIsOpen: Boolean(props.open),
      onSuccess: props.onSuccess ?? (() => {}),
    };
  }

  /**
   * Open/Show FindFilesDialog.
   * Main way to open this dialog.
   * @param {JSON} formData File mappings, mapping previous to new files.
   */
  show(formData, onSuccess = () => {}) {
    this.setState({ dialogIsOpen: true, formData, onSuccess: onSuccess });
  }

  /**
   * Close FindFilesDialog. Resets state.
   */
  handleClose = () => {
    this.setState({
      dialogIsOpen: false,
      openAccordion: null,
      formData: {
        fileMappings: [],
        projects: [],
      },
      isValidating: false,
    });
  };

  /**
   * Re-run the automatic file search and update the file mappings.
   * @param {boolean} checkUnique If true, check if all files are uniquely mapped after refresh.
   */
  handleRefresh = (checkUnique = true) => {
    const { formData } = this.state;
    Backend.checkFileMappings(
      formData,
      ProjectActionMode.Query,
      (res) => {
        // Incomplete file mappings
        if (checkUnique && !res.allUniquelyMapped) {
          window.showWarningSnackbar(
            `Not all files could be uniquely associated. Please adapt the paths of all missing or duplicate paths.`
          );
        }
        this.setState({ formData: res });
      },
      // Error
      (err) => {
        console.error(err);
        window.openWarningDialog(err);
      }
    );
  };

  /**
   * Submit all updated filepaths to backend for check and - if successfull - project completion.
   */
  handleSubmit = () => {
    const { formData } = this.state;
    const { projectActionMode } = this.props;
    this.setState({ isValidating: true });
    Backend.checkFileMappings(
      formData,
      projectActionMode,
      (res) => {
        // Incomplete file mappings
        if (!res.allUniquelyMapped) {
          window.showWarningSnackbar(
            `Not all files could be uniquely associated. Please adapt the paths of all missing or duplicate paths.`
          );
          this.setState({ formData: res });
          return;
        }
        // Import completed without changes
        this.handleClose();
        this.state.onSuccess();
        this.setState({ isValidating: false });
      },
      // Error
      (err) => {
        console.error(err);
        window.openWarningDialog(err);
        this.setState({ isValidating: false });
      }
    );
  };

  /**
   * Generates a formatted file list, so that all three types of files may ues the same code.
   * @param {Array} files Files to map to screen.
   * @returns {div} Files mapped and formatted.
   */
  generateFileList(files) {
    return (
      <LazyRender maxContainerHeight={400} elementHeight={150}>
        {files.map((f) => (
          <div key={f.file.id} style={{ marginBottom: 10 }}>
            <TextField
              value={f.file.sourcePath}
              name="Textfield"
              InputProps={{
                endAdornment: f.duplicatePath ? (
                  // Duplicate files
                  <InputAdornment position="end">
                    <Tooltip
                      disableInteractive
                      title="Multiple imported files are using this file, please specify unique path."
                    >
                      <ContentCopyIcon
                        sx={{ color: "warning.main" }}
                        color="inherit"
                      />
                    </Tooltip>
                  </InputAdornment>
                ) : f.found ? (
                  // Files found
                  <InputAdornment position="end">
                    <Tooltip
                      disableInteractive
                      title="File automatically found on your disk, no action needed."
                    >
                      <CheckIcon
                        sx={{ color: "success.main" }}
                        color="inherit"
                      />
                    </Tooltip>
                  </InputAdornment>
                ) : (
                  // Missing files
                  <InputAdornment position="end">
                    <Tooltip
                      disableInteractive
                      title="Failed to find any file with this name. Please add file and re-import or specify path."
                    >
                      <ErrorIcon sx={{ color: "error.main" }} color="inherit" />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              disabled
              margin="dense"
              label="Old Path"
              fullWidth
              variant="filled"
            />
            <TextField
              value={f.newPath}
              name="Textfield"
              onChange={(e) => {
                f.newPath = e.target.value;
                this.forceUpdate();
              }}
              margin="dense"
              label="New Path"
              fullWidth
              error={!f.found || f.duplicatePath}
            />
          </div>
        ))}
      </LazyRender>
    );
  }

  render() {
    const { dialogIsOpen, formData } = this.state;
    const { classes, projectActionMode } = this.props;
    // Only show unique source files, not one per scene of file.
    const uniqueFiles = formData.fileMappings
      .filter(
        (mapping, index, array) =>
          array.findIndex(
            (el) => el.file.sourcePath === mapping.file.sourcePath
          ) === index
      )
      .sort((a, b) => a.file.sourcePath.localeCompare(b.file.sourcePath));

    const filesFound = uniqueFiles.filter((f) => f.found);
    const missing = uniqueFiles.filter((f) => !f.found && !f.duplicatePath);
    // Duplicates are files that have the same source path as another file.
    // Sort them by their file name, to group them together for easier editing.
    const duplicates = uniqueFiles
      .filter((f) => f.duplicatePath)
      .sort((a, b) =>
        a.newPath.split("/").pop().localeCompare(b.newPath.split("/").pop())
      );

    return (
      <Dialog onClose={this.handleClose} open={dialogIsOpen} maxWidth="lg">
        <DialogTitle>
          <div>
            {_dialogContent[projectActionMode].title}
            <Tooltip title="Refresh">
              <IconButton
                style={{ float: "right" }}
                onClick={() => this.handleRefresh(false)}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            {_dialogContent[projectActionMode].text}
            Please select the corresponding files from your hard drive.
          </DialogContentText>
          <Accordion
            expanded={this.state.openAccordion === "found"}
            onChange={(_, expanded) => {
              this.setState({ openAccordion: expanded ? "found" : null });
            }}
          >
            <AccordionSummary>
              <Typography
                sx={{
                  color:
                    missing.length + duplicates.length == 0
                      ? "success.main"
                      : "inherit",
                }}
              >{`Files Found (${filesFound.length})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No further action needed. These files could automatically be
                associated with existing files inside your slides folder.
              </Typography>
              {this.generateFileList(filesFound)}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.openAccordion === "missing"}
            onChange={(_, expanded) => {
              this.setState({ openAccordion: expanded ? "missing" : null });
            }}
          >
            <AccordionSummary>
              <Typography
                sx={{ color: missing.length == 0 ? "inherit" : "error.main" }}
              >{`Files Missing (${missing.length})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                These files could not be automatically found inside your slides
                folder. Perhaps their names have changed or they need to be
                added again. Please insert missing files to the slides folder or
                specify their new name.
              </Typography>
              {this.generateFileList(missing)}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.openAccordion === "duplicate"}
            onChange={(_, expanded) => {
              this.setState({ openAccordion: expanded ? "duplicate" : null });
            }}
          >
            <AccordionSummary>
              <Typography
                sx={{
                  color: duplicates.length === 0 ? "inherit" : "error.main",
                }}
              >{`Duplicate Files (${duplicates.length})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Multiple of the imported files have been associated with the
                same file inside your slides folder. It could not be
                automatically determined which on is correct, so the first one
                was chosen. It is not allowed to have the same file twice in a
                project, therefore, please inspect all duplicated and specify
                their correct path.
              </Typography>
              {this.generateFileList(duplicates)}
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={this.state.isValidating}
            onClick={this.handleSubmit}
            color="primary"
          >
            {this.state.isValidating ? <CircularProgress /> : "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FindFilesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  projectActionMode: PropTypes.number.isRequired,
};

export default withStyles(styles)(FindFilesDialog);
