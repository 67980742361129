// CustomDialog.jsx:
// Component that provides a dialog to show information, errors, warnings and confirmations.

// Copyright HS Analysis GmbH, 2024
// Author: Viktor Eberhardt

// Framework imports
import React, { Component } from "react";

// Material UI imports
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

class CustomDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      contentList: [],
      description: [],
      dialogState: "confirm",
      open: false,
      responseFunction: null,
      title: "",
    };

    window.openResponseDialog = this.handleConfirmOpen;
    window.openErrorDialog = this.handleErrorOpen;
    window.openWarningDialog = this.handleWarningOpen;
    window.openInformationDialog = this.handleInformationOpen;
    window.openConfirmationDialog = this.awaitUserConfirmation;
  }

  handleConfirmOpen = (title, responseFunction) => {
    this.setState({
      title: title,
      content: "",
      open: true,
      dialogState: "confirm",
      responseFunction: responseFunction,
    });
  };

  /**
   * Promt the user for a yes/no decision that will pause program execution until the choise is made.
   * @param {string} title The title of the information dialog.
   * @param {string|string[]} description (List of) String. Each item displayed in a new line.
   * @param {string|string[]} content (List of) String. Each item displayed in an unordered list with a bullet point.
   * @returns {Promise} A promise that returns the user choise as boolean.
   */
  awaitUserConfirmation = async (title, description, content) => {
    return new Promise((resolve) => {
      this.setState({
        title: title,
        description:
          typeof description === "string" ? [description] : description,
        contentList: typeof content === "string" ? [content] : content,
        dialogState: "confirm",
        open: true,
        responseFunction: (confirmed) => resolve(confirmed),
      });
    });
  };

  /**
   * Open a dialog with an error message and an optional title.
   * Allow the user the option to download its contents.
   * @param {string} content The error message to display inside the dialog.
   * @param {string} title Optional. The title of the dialog. Default is "Error".
   */
  handleErrorOpen = (content, title = "Error") => {
    this.setState({
      title: title,
      content: content,
      dialogState: "error",
      open: true,
    });
  };

  /**
   * Opens a dialog with a short warning message that the user can dismiss.
   * @param {string} title The title of the warning dialog.
   */
  handleWarningOpen = (title) => {
    this.setState({
      title: title,
      content: "",
      description: [],
      contentList: [],
      dialogState: "warning",
      open: true,
    });
  };

  /**
   * Opens a dialog with a short information message that the user can dismiss.
   * @param {string} title The title of the information dialog.
   * @param {string|string[]} description (List of) String. Each item displayed in a new line.
   * @param {string|string[]} contentList (List of) String. Each item displayed in an unordered list with a bullet point.
   */
  handleInformationOpen = (title, description, contentList) => {
    this.setState({
      title: title,
      description:
        typeof description === "string" ? [description] : description,
      contentList:
        typeof contentList === "string" ? [contentList] : contentList,
      dialogState: "information",
      open: true,
    });
  };

  /**
   * Closes the custom dialog and resets state.
   *
   * @param {boolean} confirmed - Indicates whether the dialog was confirmed or not.
   */
  handleClose = (confirmed) => {
    this.setState(
      {
        open: false,
      },
      this.setState({
        title: "",
        content: "",
        description: [],
        contentList: [],
        responseFunction: null,
      })
    );
    if (this.state.dialogState === "confirm" && this.state.responseFunction) {
      this.state.responseFunction(confirmed);
    }
  };

  /**
   * Downloads the error log as a text file.
   */
  downloadError = () => {
    let filename = "hsa_error_log.txt";
    let text = this.state.content;
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  render() {
    const { dialogState, open, title, content } = this.state;
    return (
      open && (
        <Dialog
          open={open}
          onClose={() => this.handleClose(false)}
          aria-labelledby="responsive-dialog-title"
        >
          {typeof title === "object" && title.length > 1 ? (
            <DialogTitle>
              {title.map((row, idx) => {
                return <div key={idx}>{row}</div>;
              })}
            </DialogTitle>
          ) : (
            <DialogTitle>{title}</DialogTitle>
          )}

          <React.Fragment>
            {["confirm", "error", "information"].includes(dialogState) && (
              <DialogContent style={{ overflow: "visible" }}>
                {this.state.description && (
                  <DialogContentText>
                    {this.state.description.map((row, idx) => {
                      return <div key={idx}>{row}</div>;
                    })}
                  </DialogContentText>
                )}
                {this.state.contentList && (
                  <DialogContentText>
                    <ul style={{ marginTop: 8 }}>
                      {this.state.contentList.map((content, idx) => {
                        return <li key={idx}>{content}</li>;
                      })}
                    </ul>
                  </DialogContentText>
                )}

                {dialogState === "confirm" && content !== "" && (
                  <DialogContentText>{content}</DialogContentText>
                )}
                {content !== "" && (
                  <div style={{ width: 532 }}>
                    <TextField
                      label="Error Message"
                      name="Textfield"
                      multiline
                      fullWidth
                      value={content ? content : ""}
                      variant="outlined"
                    />
                  </div>
                )}
              </DialogContent>
            )}
          </React.Fragment>

          {/* Buttons */}
          <DialogActions>
            {dialogState === "confirm" && (
              <Button
                autoFocus
                onClick={() => this.handleClose(false)}
                color="primary"
              >
                Cancel
              </Button>
            )}
            {dialogState === "error" && (
              <Button onClick={() => this.downloadError()} color="primary">
                Download Log File
              </Button>
            )}
            <Button
              onClick={() => this.handleClose(true)}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  }
}

export default CustomDialog;
