import React, { Component } from "react";
import PropTypes from "prop-types";

import ProteomSettings from "../../../proteomViewer/components/ProteomSettings";
import withStyles from "@mui/styles/withStyles";

import {
  DialogContent,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const styles = () => ({
  root: {},
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    height: 562,
  },
});

class StepMetaData extends Component {
  render() {
    const {
      classes,
      formData,
      onChangeMetaData,
      projectProperties,
      projectStringProperties,
    } = this.props;
    const metaData = formData.metaData;
    return (
      <DialogContent className={classes.dialogContent}>
        <FormHelperText style={{ marginBottom: 10 }}>
          Meta Data for Project !
        </FormHelperText>
        {formData.projectType.includes("ProteomeAnalysis") ? (
          <ProteomSettings
            metaData={metaData}
            projectProperties={projectProperties}
            projectStringProperties={projectStringProperties}
            onChangeMetaData={this.props.onChangeMetaData}
          />
        ) : (
          Object.keys(metaData).map((metaField, i) => (
            <div key={i} style={{ width: "100%", position: "relative" }}>
              {metaData[metaField] === true || metaData[metaField] === false ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={metaData[metaField]}
                      onChange={(e) =>
                        onChangeMetaData(metaField, e.currentTarget.checked)
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={metaField}
                />
              ) : (
                <TextField
                  fullWidth
                  name="Textfield"
                  style={{ width: "calc(100% - 10px)", marginLeft: 6 }}
                  key={metaField}
                  margin="normal"
                  label={metaField}
                  value={metaData[metaField]}
                  onChange={(e) => onChangeMetaData(metaField, e.target.value)}
                />
              )}
              {/* <IconButton
              style={{ position: "absolute", bottom: 0, right: 0 }}
              onClick={() => onChangeMetaData(metaField, null)}
            >
              <Delete />
            </IconButton> */}
            </div>
          ))
        )}
      </DialogContent>
    );
  }
}

StepMetaData.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  projectProperties: PropTypes.object,
  projectStringProperties: PropTypes.object,
  onChangeMetaData: PropTypes.func,
};

export default withStyles(styles)(StepMetaData);
